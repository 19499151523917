import React from "react";
import { Link } from "react-router-dom";
import logo from "../../doc/img/logo/logo.png";
import logoDark from "../../doc/img/logo/footer_logo.png";
import tp_banner from "../../images/images/1.png";

const LogoArea = ({ className, dark }) => {
  return (
    <div className={`logo_area ${className ? className : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <Link to="/">
              <h3 className="logo">
                {/* <img src={dark ? logoDark : logo} alt="logo"/> */}
                {/* <img
                  src="https://www.reload.ng/reloadng/static/media/logo.7291466eadcb78486d77.png"
                  alt="logo"
                /> */}
                Reload.ng
              </h3>
            </Link>
          </div>
          <div className="col-lg-8 align-self-center">
            <div className="banner1">
              <Link to="#">
                <img src={tp_banner} alt="banner" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoArea;
